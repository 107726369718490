<template>
  <page-layout linkDefault1="/" title="Saved Posts" :backButton="true" >
  <Loading v-if="loading" />
    <div v-else class="flex flex-col justify-center items-center mt-2">
    <post v-for="(_post, i) in posts" :key="i" :post="_post" saved />
    </div>
  </page-layout>
</template>

<script>
import { GetSavedPosts } from "../../helpers/API/Feeds";
import Loading from "../../components/Loading.vue";
import Post from "../../components/Feed/Post.vue";
import pageLayout from "../../components/base/pageLayout.vue";
export default {
  components: { Post, pageLayout, Loading  },
  data() {
    return {
      user: null,
      posts: [],
      loading:true,
    };
  },
  created() {
    this.setUser();
    this.getPosts();
  },
  methods: {
    setUser() {
      this.user = this.$store.state.user;
      this.isCurrentUser = true;
      return;
    },
    async getPosts() {
      const { data } = await GetSavedPosts()
      this.posts = data.data.map(post => post.post);
      this.loading=false;
    },
  },
};
</script>
